import React, { Component } from "react";
// components

import CardLineChart from "./Cards/CardLineChart.jsx";
import CardBarChart from "./Cards/CardBarChart.jsx";
import CardPageVisits from "./Cards/CardPageVisits.jsx";
import CardSocialTraffic from "./Cards/CardSocialTraffic.jsx";
import Sidebar from "../element/SidebarAdmin.jsx";

import "../../assets/styles/tailwind.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

class AdminProjeler extends Component {
  render() {
    return (
      <>
        <Sidebar />
        <div className="relative md:ml-64 bg-blueGray-100">
     
        </div>
      </>
    );
  }
}
export default AdminProjeler;
