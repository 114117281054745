// import React from 'react';
import React, { Component,useState } from 'react';
import Router from './markup/router';
import './App.css';
import './plugins/fontawesome/css/font-awesome.min.css';
import './plugins/line-awesome/css/line-awesome.min.css';
import './plugins/flaticon/flaticon.css';
import './plugins/flaticon/beer/flaticon.css';

function FileUpload() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadMessage, setUploadMessage] = useState('');

  const fileSelectedHandler = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    } else {
      setSelectedFile(null);
     console.log("Dosya Seçilmedi")
    }
  };
  const fileUploadHandler = () => {
    const formData = new FormData();
    formData.append('file', selectedFile);
  
    fetch('/server/upload.php', {
      method: 'POST',
      body: formData
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Dosya yükleme hatası: ' + response.statusText);
      }
      return response.text(); // Sunucudan gelen yanıt metnini al
    })
    .then(data => {
      setUploadMessage(data);
    })
    .catch(error => {
      console.error('Dosya yükleme hatası:', error);
    });
  };

  return (
    <div>
      <h2>Dosya Yükleme Formu</h2>
      <input type="file" onChange={fileSelectedHandler} />
      <button onClick={fileUploadHandler}>Dosyayı Yükle</button>
      <p>{uploadMessage}</p>
    </div>
  );
}
class App extends Component {
 

  render() {

    return (
      <div className="App">
        <Router />
      </div>
    );
  }
}

export default App;
