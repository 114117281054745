import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Index from './pages/index';
import About1 from './pages/about-1';
import About2 from './pages/about-2';
import Contact1 from './pages/contact-1';
import Urun from './pages/urun';
import UrunDetay from './pages/urundetay';
import AdminLogin from './pages/admin-login';
import Index3 from './pages/index-3';
import AdminDashboard from './pages/dashboard';
import AdminBillboard from './pages/adminbillboard';
import AdminProjeler from './pages/adminprojeler';
import AdminHizmetler from './pages/adminhizmetler';
import ScrollToTop from './ScrollToTop';


class Router extends Component {
    render() {
        return (
            
			//<BrowserRouter>
			<BrowserRouter basename={'/'}>
                <ScrollToTop />
                <div className="page-wraper">

                    <Switch> 
                        <Route path='/' exact component={Index3} />
                        <Route path='/hakkimizda' component={About2} />
                        <Route path='/urun/detay/:id' component={UrunDetay} />
                        <Route path='/urun/:id' component={Urun} />
                        <Route path='/iletisim' component={Contact1} />
     
                         {/* ADM�N */}
                   
        

                    </Switch>
                </div>
                <Switch> 
                
                         {/* ADM�N */}
                        <Route path='/admin/dashboard' component={AdminDashboard} />
                        <Route path='/admin/billboard' component={AdminBillboard} />
                        <Route path='/admin/hizmetler' component={AdminHizmetler} />
                        <Route path='/admin/projeler' component={AdminProjeler} />
                        <Route path='/admin' component={AdminLogin} />
        

                    </Switch>

            </BrowserRouter>

        )
    }
}

export default Router;