import React, { useRef } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

const bg = require("../../images/background/bg3.png");
// style="background-image:url(images/background/bg3.png); background-size: cover;"

const Footer4 = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    //emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
    emailjs
      .sendForm(
        "service_0btii1e",
        "service_0btii1e",
        e.target,
        "d9b2e0f5fc72cb94792110e8ff2028f3-us16"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <>
      <footer className="site-footer">
        <div
          className="footer-top"
          style={{ backgroundImage: "url(" + bg + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-8 col-xl-4 col-lg-4 col-sm-8 footer-col-6">
                <div className="widget widget_services border-0">
                  <ul>
                    <li>
                      <Link to="/about"><img src={require("../../images/galsiad-logo.png")} alt=""/></Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-6 col-xl-4 col-lg-3 col-sm-6 footer-col-4">
                <div className="widget widget_getintuch">
                  <h5 className="footer-title text-white">
                    İletişim Bilgileri
                  </h5>
                  <ul>
                    <li>
                      <i className="ti-location-pin"></i>
                      <strong>adres</strong> Hürriyet Mah. Dr. Cemil Bengü Cad.
                      No:24/A 34403 Çağlayan Kağıthane İstanbul
                    </li>
                    <li>
                      <i className="ti-mobile"></i>
                      <strong>telefon</strong>+90 212 224 41 15
                    </li>
                    <li>
                      <i className="ti-email"></i>
                      <strong>e-mail</strong>info@alcometal.com.tr
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 col-xl-4 col-lg-4 col-sm-6 footer-col-4 ">
                <div
                  className="col-lg-5 col-lg-12 col-md-12 wow fadeIn"
                  data-wow-delay="0.6s"
                >
                  <div className="icon-bx-wraper bx-style-1 p-a30 m-b15 radius-sm br-col-w1 bg-tpw1">
                    <h5 className="text-white font-weight-300">Hakkımızda</h5>
                    <p>
                      Maco Makina Sanayi A.Ş., 1950’lerden itibaren Türkiye’de
                      metal sektöründe hizmet veren aile şirketinin devamıdır.
                      Yeni oluşan iş modeli ile Alüminyum sektörüne taze bir güç
                      olarak giriş yapmıştır.{" "}
                    </p>
                  </div>
                </div>
                <ul
                  className="list-inline m-a0"
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <li>
                    <Link to="#" className="site-button facebook circle ">
                      <i className="fa fa-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="site-button linkedin circle ">
                      <i className="fa fa-linkedin"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="site-button instagram circle ">
                      <i className="fa fa-instagram"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-6 text-left ">
                {" "}
                <span>Copyright © 2024 P4CK</span>{" "}
              </div>
              
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer4;
