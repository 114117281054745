import React, { Component,useState } from "react";
// components

import CardLineChart from "./Cards/CardLineChart.jsx";
import CardBarChart from "./Cards/CardBarChart.jsx";
import CardPageVisits from "./Cards/CardPageVisits.jsx";
import CardSocialTraffic from "./Cards/CardSocialTraffic.jsx";
import Sidebar from "../element/SidebarAdmin.jsx";
import EditableList from "../element/mainbanneredit.jsx";

import "../../assets/styles/tailwind.css";
import "@fortawesome/fontawesome-free/css/all.min.css";


const sampleData = [
  "Item 1",
  "Item 2",
  "Item 3",
  "Item 4",
  "Item 5"
];

class AdminHizmetler extends Component {
  render() {
    return (
      <>
        <Sidebar />
        <div className="relative md:ml-64 ">
      <div className="relative m-20 rounded-2xl bg-admin-100">
      <h1 className="text-center text-white font-weight-bolder text-5xl p-6 ">Hizmetler</h1>
      </div>
      <div className=" min-h-screen min-w-full  p-6 m-auto">

     <EditableList items={sampleData}/>
    </div>
    </div>
      
      </>
    );
  }
}
export default AdminHizmetler;
