import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

const bg = require("../../images/banner/bnr1.jpg");
const AdminLoginComp = () => {
  const history = useHistory();
  return (
    <div className="form-container" style={{padding:"6vh"}}>
      <h4 className="font-weight-700">Giriş</h4>
      <Formik
        initialValues={{ username: '', password: '' }}
        validationSchema={Yup.object({
          username: Yup.string().required('Kullanıcı adı gereklidir.'),
          password: Yup.string().required('Şifre gereklidir.'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
 
            if (values.username === 'admin' && values.password === 'admin') {
              history.push('/admin/dashboard');
            } else {
              alert('Geçersiz kullanıcı adı veya şifre.');
            }
            setSubmitting(false);
          }, 400);
        }}
      >
        <Form>
          <div className="form-group">
            <label htmlFor="username">Kullanıcı Adı:</label>
            <Field type="text" id="username" name="username" />
            <ErrorMessage name="username" />
          </div>
          <div className="form-group">
            <label htmlFor="password">Şifre:</label>
            <Field type="password" id="password" name="password" />
            <ErrorMessage name="password" />
          </div>
          <div className="text-center">
            <button type="submit" className="site-button m-r5 button-lg radius-no">
              Giriş
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

class AdminLogin extends Component {
  render() {
    return (
      <>
        <div className="page-content bg-white">
          <div
            className="dlab-bnr-inr overlay-black-middle bg-pt"
            style={{ backgroundImage: "url(" + bg + ")" }}
          >
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <div className="breadcrumb-row"></div>
              </div>
            </div>
          </div>

          <div className="section-full content-inner shop-account">
            <div className="container">
              <div className="row ">
                <div className="col-lg-12 text-center">
                  <h2 className="font-weight-700 m-t0 m-b40">Admin Paneli</h2>
                </div>
              </div>
              <div className="row dzseth" style={{ justifyContent: "center" }}>
                <div className="col-lg-6 col-md-6 m-b30">
                  <div className="">
                    <div className="tab-content nav">
                      <AdminLoginComp />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default AdminLogin;
