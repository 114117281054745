import React, { Component,useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';
import axios from "axios";

const flagsFolder = '/flags';

const LanguageDropdown = () => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://www.omurcanuslu.com/server/GetLanguage"
        );
        setCountryData(response.data);
        let savedCountry = localStorage.getItem('selectedCountry');
        if (savedCountry) {
            const filteredData = response.data.filter(item => item.ID === savedCountry);

          setSelectedCountry(filteredData[0].LanguageIconName);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    
    fetchData();
   

  }, []);

  const handleCountryChange = (code) => {
    setSelectedCountry(code);
    // Seçili ülke kodunu LocalStorage'a kaydet
    const fetchData = async () => {
        try {
          const response = await axios.post(
            "https://www.omurcanuslu.com/server/GetLID",{
                LanguageIconName: code
            }
          );
          localStorage.setItem('selectedCountry', response.data[0].ID);
          window.location.reload();

        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData();
  

  };

  return (
    <div className="dropdown">
      <button className="dropbtn">
        {selectedCountry ? (
          <img src={`${flagsFolder}/${selectedCountry}.svg`} width={25} alt={`${selectedCountry} flag`} />
        ) : (
            <img src={`${flagsFolder}/tr.svg`} width={25} alt={`${selectedCountry} flag`} />
        )}
      </button>
      <div className="dropdown-content">
        {/* Her bir ülke için bir dropdown seçeneği oluşturun */}
        {countryData.map((country) => (
          <button key={country.ID} onClick={() => handleCountryChange(country.LanguageIconName)}>
            <img src={`${flagsFolder}/${country.LanguageIconName}.svg`} width={25} alt={`${country.LanguageIconName} flag`} />
            {country.LanguageName}
          </button>
        ))}
      </div>
    </div>
  );
};

class Header3 extends Component {

    state = {
        fixed:false
    }
    componentDidMount() {

        
        // sidebar open/close

        var btn = document.querySelector('.navicon');
        var nav = document.querySelector('.header-nav');
       
        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);


        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.header-nav > ul > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }

        function checkLi(current) {
            const active = current.classList.contains("open")
            navUl.forEach(el => el.classList.remove('open'));
            current.classList.add('open');
           
            if(active){
                current.classList.remove('open') 
                console.log("active")
            } else{
                current.classList.add('open');
                console.log("close")
            }
        }
        var btn = document.querySelector('.navicon');
        var nav = document.querySelector('.header-nav');
       
        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);


        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.header-nav > ul > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }

        function checkLi(current) {
            const active = current.classList.contains("open")
            navUl.forEach(el => el.classList.remove('open'));
            //current.classList.add('open');
           
            if(active){
                current.classList.remove('open') 
                //console.log("active")
            } else{
                current.classList.add('open');
                //console.log("close")
            }
        }

    }

    handleStateChange = (status)=> {
        const fixed = status.status === Sticky.STATUS_FIXED ? true : false
        
        this.setState({
            fixed
        })
        

    }

    render() {
        const active = this.state.fixed ? "is-fixed" : ""
        return (
            <>
             <Sticky 
             innerZ={999} 
             enabled={true}
             onStateChange={this.handleStateChange}
             >
                <header className="site-header mo-left header-transparent header navstyle3">
                    <div class={`sticky-header main-bar-wraper ${active} navbar-expand-lg`}>
                        <div className="main-bar clearfix ">
                            <div className="container clearfix">
                                <div className="logo-header mostion">
                                    <Link to="/"><img src={require("../../images/maco-makina.png")} className='bg-logo-50 rounded-md' alt=""/></Link>
                                </div>
                                
                                <button className="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                              
                                <div className="header-nav navbar-collapse collapse justify-content-end" id="navbarNavDropdown">
                                    <div className="logo-header d-md-block d-lg-none">
                                        <Link to="/"><img src={require("../../images/logo.png")} alt=""/></Link>
                                    </div>
                                    <ul className="nav navbar-nav">	
                                        <li className="has-mega-menu homedemo"> <Link to="/">Anasayfa<i className=""></i></Link>
                                        </li>
                                        <li className="has-mega-menu homedemo"> <Link to="/hakkimizda">Hakkımızda<i className=""></i></Link>
                                        </li>
                                      
                                        <li>
                                            <Link to="#">Ürünler<i className="fa fa-chevron-down"></i></Link>
                                            <ul className="sub-menu">
                                                <li><Link to="/urun/1">PuraDyn Filtre Sistemi</Link></li>
                                                <li><Link to="/urun/2">PuraDyn MTS-40</Link></li>
                                                <li><Link to="/urun/3">PuraDYN MTS-60</Link></li>
                                                <li><Link to="/urun/4">PuraDYN MTS-240</Link></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link to="#">Hizmetler<i className="fa fa-chevron-down"></i></Link>
                                            <ul className="sub-menu">
                                                <li><Link to="/">Makina Tedariği</Link></li>
                                                <li><Link to="/">Test Kurulumu</Link></li>
                                                <li><Link to="/">Danışmanlık</Link></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link to="/iletisim">İletişim</Link>
                                            
                                        </li>
                                       
                                            
                                        

                                    </ul>	
                                            <LanguageDropdown/>
                                    <div className="dlab-social-icon">
                                        <ul>
                                            <li><Link className="site-button facebook sharp-sm fa fa-facebook" to="#"></Link></li>
                                            <li><Link className="site-button twitter sharp-sm fa fa-twitter" to="#"></Link></li>
                                            <li><Link className="site-button linkedin sharp-sm fa fa-linkedin" to="#"></Link></li>
                                            <li><Link className="site-button instagram sharp-sm fa fa-instagram" to="#"></Link></li>
                                        </ul>
                                    </div>			
                                </div>
                            </div>
                        </div>
                    </div>
                </header> 
            </Sticky>               
            </>
        )
    }
}
export default Header3;