/*eslint-disable*/
import React ,{useState} from "react";
import { Link } from "react-router-dom";

import NotificationDropdown from "./Dropdowns/NotificationDropdown.jsx";
import UserDropdown from "./Dropdowns/UserDropdown.jsx";

function SidebarAdmin() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);
  return (
    <>
<div className="fixed top-0 left-0 w-64  z-10 py-4 px-6 pb-4">
  <Link
    className="text-blueGray-600 text-left text-sm uppercase font-bold block py-2  items-center"
    to="/"
  >
    <img
      src={require("../../images/maco-makina.png")}
      className="radius-sm h-20 mr-2"
      alt=""
    />
  </Link>
</div>
<nav className="md:left-0 md:block  md:fixed md:top-36 md:bottom-0 md:overflow-y-auto  md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl  flex flex-wrap items-center justify-between relative md:w-64 z-10 px-6">
  <div className="md:flex-col px-0 flex flex-wrap items-center justify-between w-full mx-auto ">
    {/* Toggler */}
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
       
          {/* User */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 p-2  md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/"
                  >
                    Notus React
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
         
    
            {/* Navigation */}
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className="text-xs uppercase py-3 font-bold block text-blueGray-700 hover:text-blueGray-500"
                  to="/admin/dashboard"
                >
                  <i className="fas fa-tv mr-2 text-sm text-white"></i>{" "}
                  Dashboard
                </Link>
              </li>

              {/* ANASAYFA Men� */}
              <li className="items-center relative">
                <a
                  className="text-xs uppercase py-3 font-bold block text-blueGray-700 hover:text-blueGray-500 cursor-pointer"
                  onMouseEnter={() => setShowSettingsMenu(!showSettingsMenu)}
                >
                  <i className="fas fa-tools mr-2 text-sm text-white"></i>{" "}
                  Anasayfa
                </a>
                {/* Alt Menü */}
                <ul
                  className={`absolute z-50 ${
                    showSettingsMenu ? "block" : "hidden"
                  } text-sm bg-orange-200 rounded-2xl shadow-md mt-2 py-2 w-full`}
                >
                  <li className="py-4 hover:bg-admin-600 rounded-lg">
                    <Link to="/admin/billboard"> Billboard (Banner)</Link>
                  </li>
                  <li className="py-4 hover:bg-admin-600 rounded-lg">
                    <Link to="/admin/hizmetler">Hizmetler</Link>
                  </li>
                  <li className="py-4 hover:bg-admin-600 rounded-lg">
                    <Link to="/admin/projeler">Projelerimiz</Link>
                  </li>
                </ul>
              </li>

              {/* Ürünler Bilgileri */}
              <li className="items-center relative">
                <a
                  className="text-xs uppercase py-3 font-bold block text-blueGray-700 hover:text-blueGray-500 cursor-pointer"
                 
                >
                  <i className="fas fa-tools mr-2 text-sm text-white"></i>{" "}
                  
                  <Link to="/admin/settings/general"> Ürünler</Link>
                </a>
            
              </li>
              {/* Hakkımızda Bilgileri */}
              <li className="items-center relative">
                <a
                  className="text-xs uppercase py-3 font-bold block text-blueGray-700 hover:text-blueGray-500 cursor-pointer"
                 
                >
                  <i className="fas fa-tools mr-2 text-sm text-white"></i>{" "}
                  
                  <Link to="/admin/settings/general"> Hakkımızda</Link>
                </a>
            
              </li>
              {/* İletişim Bilgileri */}
              <li className="items-center relative">
                <a
                  className="text-xs uppercase py-3 font-bold block text-blueGray-700 hover:text-blueGray-500 cursor-pointer"
             
                >
                  <i className="fas fa-tools mr-2 text-sm text-white"></i>{" "}
                  
                  <Link to="/admin/settings/general"> İletişim</Link>
                </a>
            
              </li>
            </ul>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Auth Layout Pages
            </h6>
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="items-center">
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  to="/auth/login"
                >
                  <i className="fas fa-fingerprint text-blueGray-400 mr-2 text-sm"></i>{" "}
                  Login
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  to="/auth/register"
                >
                  <i className="fas fa-clipboard-list text-white mr-2 text-sm"></i>{" "}
                  Register
                </Link>
              </li>
            </ul>


     
          </div>
        </div>
      </nav>
    </>
  );
}
export default SidebarAdmin;
