import React, { Component } from "react";
// components

import CardLineChart from "./Cards/CardLineChart.jsx";
import CardBarChart from "./Cards/CardBarChart.jsx";
import CardPageVisits from "./Cards/CardPageVisits.jsx";
import CardSocialTraffic from "./Cards/CardSocialTraffic.jsx";
import Sidebar from "../element/SidebarAdmin.jsx";
import AdminNavbar from "../element/AdminNavbar.jsx";
import HeaderStats from "../element/HeaderStats.jsx";
import "../../assets/styles/tailwind.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

class Dashboard extends Component {
  render() {
    return (
      <>
        <Sidebar />
        <div className="relative md:ml-64 bg-blueGray-100">
     
        <HeaderStats />
          <div className="px-4 md:px-10 mx-auto w-full -m-24">
            <div className="flex flex-wrap">
       
            </div>
            {/* <div className="flex flex-wrap mt-4">
              <div className="w-full mb-12 xl:mb-0 px-4">
               <CardPageVisits />
              </div>
            
            </div> */}
          </div>
        </div>
      </>
    );
  }
}
export default Dashboard;
