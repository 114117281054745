import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header3'
import Footer from '../layout/footer4'

var bnr3 = require('./../../images/banner/bnr3.jpg');

class About2 extends Component {


    render() {
        return (
            <>
                <Header/>
                
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr overlay-black-middle bg-pt" style={{ backgroundImage: "url(" + bnr3 + ")" }}>
                        <div className="container">
                            <div className="dlab-bnr-inr-entry">
                                <h1 className="text-white">Hakkımızda</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to={"/"}>Anasayfa</Link></li>
                                        <li>Hakkımızda</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="content-block">
                        <div className="section-full content-inner bg-white">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-md-12 m-b30">
                                        <div className="our-story">
                                            <span>HİKAYEMİZ</span>
                                            <h2 className="title" style={{fontSize: "3rem"}}>Türkiye Metal sektöründe <br/>50 yılı aşkın süredir <br/> hizmet veriyoruz<br/><span className="text-primary">since 1972</span></h2>
                                            <h4 className="title">Geleceği öngören bir yapı ve tecrübe ile Alüminyum un yakın gelecekte ülkemiz için ne kadar önemli, stratejik olduğunu belirledik. </h4>
                                            <p>Dünya da yapılan çalışmaları eş zamanlı takip ettik. Ülkemiz Üniversiteleri ve akademisyenleri ile bir arada çalışmalar yaptık. Yakın döneme kadar “ demir dışı metaller„ kategorisinde değerlendirilen Alüminyum u tüm platformlarda gündem ve konuşulur hale getirdik. 
                Ülkemizde bir fabrika nın devlet eli ile yaptığı yatırımın gelişmesini takip ederek bugün dünyanın 10. Büyük üretim kapasitesine sahip ülkesi olmaya kadar olan tüm serüvende bir şekilde yer aldık. 
                  Boksit madeni ile başlayarak nitelikli Alüminyum ürünlerin oluşmasına kadar olan tüm sürece hâkim olan kadromuz özellikle son 20 yıldır yapılan yatırımlara makine ekipman, personel, idari ve kurumsal tüm alanlarda yönlendirici olmuştur. 
                  Doğru yatırım, doğru makine ekipman, doğru personel, doğru proje yatırımları doğru sonuçlara götürdüğü bilgisi ile her zaman bu yol haritasını ön gördük. 
                   Günümüzde özellikle Alüminyum metalinin stratejik sektörler olan, savunma, otomotiv, havacılık, raylı sistemler, uzay sanayinde kullanım alanları ve bu alanların genişletilmesi. Bu alanlara özel ürünlerin üretilebileceği hatların kurulması veya mevcut üretim hatlarının bu konulara uygun hale getirilmesi sizlere sunacağımız hizmetler içerisindedir.
                     Hammadde üretiminden, mamul ürüne uzanan bu yol haritasında özellikle enerji kullanımı, üretim hızı, kalite ve yeşil üretime uygun proseslerin oluşturulması yine şirketimiz uzmanlık konuları arasındadır.  
                      Önümüzdeki süreçte planlanan yatırım ve mevcut yatırımların rehabilitasyonu konularında sizlere sunacağımız hizmet detaylarına şirketimiz faaliyetlerini inceleyerek ulaşabilirsiniz. Planlama, Tasarım, satın alma, kurulum, işletmeye alma, programlama, tüm idari süreçler için uzman kadrolar ile sizlerin hizmetindeyiz. 
</p>
                                         
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 m-b30 our-story-thum">
                                        <img src={require("../../images/about/pic6.jpg")} className="radius-sm" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="section-full box-about-list">
                            <div className="row spno">
                                <div className="col-lg-6 col-md-12"> 
                                    <img src={require("../../images/about/pic3.jpg")} alt="" className="img-cover"/> 
                                </div>
                                <div className="col-lg-6 col-md-12 bg-primary">
                                    <div className="max-w700 m-auto p-tb50 p-lr20">
                                        <div className="text-white">
                                            <h2>Gelişiyoruz <br/> <div>&nbsp; ve</div> <div>&nbsp;&nbsp;&nbsp; İnşa Ediyoruz</div></h2>
                                        </div>
                                        <div className="icon-bx-wraper m-b30 left">
                                            <div className="icon-md"> 
                                                <Link to={"/#"} className="icon-cell text-white">
                                                    <i className="flaticon-optimization"></i>
                                                </Link> 
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="dlab-tilte">Makina Tedariği</h4>
                                                <p> Maco Makina, Alüminyum üretim tesisinde Alüminyum döküm,
                      profil, levha, yüzey işlem ve ısıl işlem hatlarının makina
                      ve ekipmanların tedariğini sağlamaktadır.</p>
                                            </div>
                                        </div>
                                        <div className="icon-bx-wraper m-b30 left">
                                            <div className="icon-md"> 
                                                <Link to={"/#"} className="icon-cell text-white">
                                                    <i className="flaticon-design-tools"></i>
                                                </Link> 
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="dlab-tilte">Test Kurulumu</h4>
                                                <p> Maco Makina, Alüminyum üretim tesisindeki Alüminyum döküm,
                      profil, levha, yüzey işlem ve ısıl işlem hatlarının
                      entegre olarak çalışması ve sistemin kurulmasını sağlar.</p>
                                            </div>
                                        </div>
                                        <div className="icon-bx-wraper left">
                                            <div className="icon-md"> 
                                                <Link to={"/#"} className="icon-cell text-white">
                                                    <i className="flaticon-analytics"></i>
                                                </Link> 
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="dlab-tilte">Makina Tedariği</h4>
                                                <p>          Maco Makina, Alüminyum üretim tesisinde Alüminyum döküm,
                      profil, levha, yüzey işlem ve ısıl işlem hatlarının makina
                      ve ekipmanların tedariğini sağlamaktadır.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
           
                  
                    </div>
                </div>
                <Footer/>
            </>
        )
    }
}
export default About2;