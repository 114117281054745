import React, { Component, useState} from "react";
// components

import CardLineChart from "./Cards/CardLineChart.jsx";
import CardBarChart from "./Cards/CardBarChart.jsx";
import CardPageVisits from "./Cards/CardPageVisits.jsx";
import CardSocialTraffic from "./Cards/CardSocialTraffic.jsx";
import Sidebar from "../element/SidebarAdmin.jsx";
import HeaderStats from "../element/HeaderStats.jsx";

import "../../assets/styles/tailwind.css";
import "@fortawesome/fontawesome-free/css/all.min.css";



const BilboardForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData); 
  };

return(
  <>

  
   

      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            placeholder="Your name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            placeholder="Your email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
            Message
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="message"
            placeholder="Your message"
            name="message"
            value={formData.message}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Send
          </button>
        </div>
      </form>
  
  </>
)

}

class Billboard extends Component {
  render() {
  
    return (
      <>
        <Sidebar />
        <div className="relative md:ml-64 ">
      <div className="relative m-20 rounded-2xl bg-admin-100">
      <h1 className="text-center text-white font-weight-bolder text-5xl p-6 ">Banner</h1>
      </div>
      <div className=" min-h-screen min-w-full  p-6 m-auto">
      <BilboardForm />
    </div>
    </div>
     
      </>
    );
  }
}
export default Billboard;
