import React, { Component, useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header3";
import Footer from "../layout/footer4";
import axios from "axios";
// Sliders
import Testimonial1 from "./../element/testimonial1";
import BlogSlider1 from "../element/blogSlider1";
import {
  blogContent3,
  projectContent1,
  homeSliderContent3,
} from "../element/SliderContent";
import ProjectSlider1 from "../element/projectSlider1";
import HomeSlider from "../element/homeslider1";
import ScrollToTop from "../element/scrollToTop";

// import '../../css/skin/skin-2.min.css';

const bg = require("../../images/background/map-bg.png");
const ServicesBar = () => {
  const [service, setService] = useState([]);
  let Lang = localStorage.getItem("selectedCountry");
  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await axios.post(
                "https://www.omurcanuslu.com/server/GetService", {
                LanguageID: Lang || "0"
            });

            setService(response.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    fetchData();
}, [Lang]);

  return (
    <>
      <div className="section-full bg-gray content-inner">
        <div className="container">
          <div className="section-head text-center">
            <h2 className="title">Hizmetlerimiz</h2>
          </div>
          <div className="section-content row">
             {service.map((item) => (
    <div
        className="col-md-6 col-lg-4 col-sm-12 service-box style3 wow fadeInUp"
        data-wow-duration="2s"
        data-wow-delay="0.2s"
        key={item.ID}
    >
        <div className="icon-bx-wraper" data-name={item.OrderNo}>
            <div className="icon-lg">
                <Link to="#" className="icon-cell">
                    <i className={item.IconName}></i>
                </Link>
            </div>
            <div className="icon-content">
                <h2 className="dlab-tilte">{item.ServiceName}</h2>
                <p>{item.ServiceContent}</p>
            </div>
        </div>
    </div>
))}
            
          </div>
        </div>
      </div>
    </>
  );
};


class Index3 extends Component {
  render() {
    return (
      <>
        <Header />

        <HomeSlider
          data={homeSliderContent3}
          contentWrapperCls="sliderStyle3"
          hideBtn
        />

        <div className="page-content bg-white">
          <div className="content-block">
            <ServicesBar />

            <div className="section-full content-inner bg-white">
              <div className="container">
                <div className="row align-items-center">
                  <div
                    className="col-lg-6 col-md-6 m-b30 wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay="0.3s"
                  >
                    <div className="our-story">
                      <h2 className="title">
                        Yüksek Kalitede
                        <br /> <span className="text-primary">Kararlıyız </span>
                      </h2>
                      <h4 className="title">
                        Hammadde üretiminden, mamul ürüne uzanan bu yol
                        haritasında özellikle enerji kullanımı, üretim hızı,
                        kalite ve yeşil üretime uygun proseslerin oluşturulması
                        yine şirketimiz uzmanlık konuları arasındadır.
                      </h4>
                      <Link to="/about" className="site-button btnhover14">
                        Daha Fazlası
                      </Link>
                    </div>
                  </div>
                  <div
                    className="col-lg-6 col-md-6 m-b30 wow fadeInUp"
                    data-wow-duration="2s"
                    data-wow-delay="0.6s"
                  >
                    <img
                      src={require("../../images/about/about-1.jpg")}
                      className="radius-sm"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="section-full content-inner-2 bg-gray wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay="0.4s"
            >
              <div className="container">
                <div className="section-head text-black text-center">
                  <h2 className="title">Projelerimiz</h2>
                
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <ProjectSlider1 data={projectContent1} />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="section-full call-action style1 bg-primary wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.2s"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-9 text-white">
                    <h2 className="title">
                      Projeleriniz için size yardımcı olabiliriz. <br />
                      Detaylı bilgi ve fiyat teklif için lütfen talebinizi
                      iletin.
                    </h2>
                  </div>
                  <div className="col-lg-3 d-flex">
                    <Link
                      to="/contact"
                      className="site-button black align-self-center ms-auto btnhover14"
                    >
                      Bize Ulaş
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ScrollToTop className="style1 radius white" />
      </>
    );
  }
}
export default Index3;
