import React, { Component,useRef,useState } from "react";
// import { Link } from 'react-router-dom';
import Header from "../layout/header3";
import Footer from "../layout/footer4";
import emailjs from "@emailjs/browser";
import axios from 'axios';
const bg = require("../../images/background/bg5.jpg");


const MyEmail = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);
    const name = formData.get("dzName");
    const phone = formData.get("dzOther[Phone]");
    const email = formData.get("dzEmail");
    const message = formData.get("dzMessage");


    emailjs.send('service_0btii1e', 'template_xpxhqtc', {
      to_email: 'omurcanuslu@outlook.com', 
      message: message, from_name:name ,phone:phone , email:email
    },'yWrf5DHaT0GPYpKtI')
    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  e.target.reset();
  };
  
  function FileUpload() {
    const [selectedFile, setSelectedFile] = useState(null);
  
    const fileSelectedHandler = (event) => {
      setSelectedFile(event.target.files[0]);
    };
  
    const fileUploadHandler = () => {
      const formData = new FormData();
      formData.append('file', selectedFile);
  
      // Sunucuya dosyayı yükleme
      axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        console.log(response.data);
      }).catch(error => {
        console.error('Dosya yükleme hatası:', error);
      });
    };
  
    return (
      <div>
        <input type="file" onChange={fileSelectedHandler} />
        <button onClick={fileUploadHandler}>Dosyayı Yükle</button>
      </div>
    );
  }
  return (
    <form
    className="inquiry-form wow box-shadow bg-white fadeInUp"
    data-wow-delay="0.2s"
    ref={form} onSubmit={sendEmail}
  >
    <h3 className="title-box font-weight-300 m-t0 m-b10">
      Bize Ulaş <span className="bg-primary"></span>
    </h3>
    <div className="row">
      <div className="col-lg-6 col-md-6">
        <div className="form-group">
          <div className="input-group">
            <span className="input-group-addon">
              <i className="ti-user text-primary"></i>
            </span>
            <input
              name="dzName"
              type="text"
              required
              className="form-control"
              placeholder="İsim Soyisim"
            />
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-6">
        <div className="form-group">
          <div className="input-group">
            <span className="input-group-addon">
              <i className="ti-mobile text-primary"></i>
            </span>
            <input
              name="dzOther[Phone]"
              type="text"
              required
              className="form-control"
              placeholder="Telefon"
            />
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12">
        <div className="form-group">
          <div className="input-group">
            <span className="input-group-addon">
              <i className="ti-email text-primary"></i>
            </span>
            <input
              name="dzEmail"
              type="email"
              className="form-control"
              required
              placeholder="E-mail"
            />
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12">
        <div className="form-group">
          <div className="input-group">
            <span className="input-group-addon">
              <i className="ti-agenda text-primary"></i>
            </span>
            <textarea
              name="dzMessage"
              rows="4"
              className="form-control"
              required
              placeholder="İletiniz"
            ></textarea>
          </div>
        </div>
      </div>
      <div className="col-lg-12 col-md-12">
        <button
          name="submit"
          type="submit"
          value="Submit"
          className="site-button button-md"
        >
          {" "}
          <span>Bize Ulaş</span>{" "}
        </button>
      </div>
    </div>
  </form>
  );
};

class Contact1 extends Component {
  render() {

    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div
            className="section-full content-inner contact-page-9 overlay-black-dark"
            style={{ background: "url(" + bg + ") 30% 100%" }}
          >
            <div className="container">
              <div className="row mt-5">
                <div className="col-lg-4 col-md-12 text-white">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 m-b30">
                      <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                        <div className="icon-content">
                          <h5 className="dlab-tilte">
                            <span className="icon-sm text-primary">
                              <i className="ti-location-pin"></i>
                            </span>
                            Şirket Adresi
                          </h5>
                          <p>
                            Hürriyet Mah. Dr. Cemil Bengü Cad. No:24/A 34403
                            Çağlayan Kağıthane İstanbul
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6 m-b30">
                      <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                        <div className="icon-content">
                          <h5 className="dlab-tilte">
                            <span className="icon-sm text-primary">
                              <i className="ti-email"></i>
                            </span>
                            E-mail
                          </h5>
                          <p className="m-b0">info@alcometal.com.tr</p>
                          <p className="m-b0">info@macometal.com.tr</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6 m-b30">
                      <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                        <div className="icon-content">
                          <h5 className="dlab-tilte">
                            <span className="icon-sm text-primary">
                              <i className="ti-mobile"></i>
                            </span>
                            Telefon Numaraları
                          </h5>
                          <p className="m-b0">+90 212 224 41 15</p>
                          <p className="m-b0">+91-12345-67890</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-12 m-b30">
                <MyEmail/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
export default Contact1;
