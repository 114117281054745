import React, { useState } from 'react';

import ReactImagePickerEditor, { ImagePickerConf } from 'react-image-picker-editor';
import 'react-image-picker-editor/dist/index.css'


const ListItem = ({ item, onEdit }) => {
  return (
    <div className="flex items-center justify-between border-b border-gray-300 py-2">
      <span>{item}</span>
      <button
        onClick={() => onEdit(item)}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
      >
        Edit
      </button>
    </div>
  );
};

const EditPopup = ({ item, onSave, onClose }) => {
  const [editedItem, setEditedItem] = useState(item);

  const handleChange = (e) => {
    setEditedItem(e.target.value);
  };

  const handleSave = () => {
    onSave(editedItem);
    onClose();
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-8 rounded shadow-md">
        <h2 className="text-lg font-bold mb-4">Edit Item</h2>
        <input
          type="text"
          value={editedItem}
          onChange={handleChange}
          className="border border-gray-400 rounded px-3 py-2 w-full mb-4"
        />
        <div className="flex justify-end">
          <button
            onClick={handleSave}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2 focus:outline-none focus:shadow-outline"
          >
            Save
          </button>
          <button
            onClick={onClose}
            className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const AddPopup = ({ onSave, onClose }) => {
  const [newItem, setNewItem] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleChange = (e) => {
    setNewItem(e.target.value);
  };

  const handleSave = () => {
    onSave(newItem);
    onClose();
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-white p-8 rounded shadow-md">
        <h2 className="text-lg font-bold mb-4">Add New Item</h2>
        <input
          type="text"
          value={newItem}
          onChange={handleChange}
          className="border border-gray-400 rounded px-3 py-2 w-full mb-4"
        />
        <ReactImagePickerEditor
          config={{
            borderRadius: '8px',
            language: 'en',
            width: '400px',
            height: '300px',
            objectFit: 'cover',
            compressInitial: null,
          }}
          imageSrcProp={selectedFile ? URL.createObjectURL(selectedFile) : ''}
          onImageChange={(imageDataUri) => {
            const img = new Image();
            img.onload = () => {
              setWidth(img.width);
              setHeight(img.height);
              setSelectedFile(imageDataUri);
            };
            img.src = imageDataUri;
          }}
        />
        {selectedFile && (
          <div>
            <p>Genişlik: {width}px, Yükseklik: {height}px</p>
          </div>
        )}
        <div className="flex justify-end">
          <button
            onClick={handleSave}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2 focus:outline-none focus:shadow-outline"
          >
            Add
          </button>
          <button
            onClick={onClose}
            className="bg-gray-400 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};


const EditableList = ({ items }) => {
  const [editItem, setEditItem] = useState(null);
  const [addItemOpen, setAddItemOpen] = useState(false);

  const handleEdit = (item) => {
    setEditItem(item);
  };

  const handleCloseEdit = () => {
    setEditItem(null);
  };

  const handleSaveEdit = (editedItem) => {
    // Burada, editedItem'i orijinal listedeki uygun indekse gï¿½re gï¿½ncellemek iï¿½in bir iï¿½lev yazabilirsiniz
    console.log("Edited Item:", editedItem);
  };

  const handleAddItem = () => {
    setAddItemOpen(true);
  };

  const handleCloseAddItem = () => {
    setAddItemOpen(false);
  };

  const handleSaveAddItem = (newItem) => {
    // Burada, newItem'i orijinal listeye eklemek iï¿½in bir iï¿½lev yazabilirsiniz
    console.log("New Item:", newItem);
    handleCloseAddItem();
  };

  return (
    <div>
      <button
        onClick={handleAddItem}
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mb-4 focus:outline-none focus:shadow-outline"
      >
        Add New Item
      </button>
      {items.map((item, index) => (
        <ListItem key={index} item={item} onEdit={handleEdit} />
      ))}
      {editItem && (
        <EditPopup item={editItem} onSave={handleSaveEdit} onClose={handleCloseEdit} />
      )}
      {addItemOpen && (
        <AddPopup onSave={handleSaveAddItem} onClose={handleCloseAddItem} />
      )}
    </div>
  );
};

export default EditableList;
